import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { ORCH_PATHS } from '../utils/constants';
import orchestration from '../utils/orchestration';
import { createError } from '../utils/reportError';

const trustpilotValidator = z.object({
    trustpilotEligible: z.boolean().nullable().default(false),
    trustpilotUrl: z.string().nullable().default(null),
});

export const useTrustpilot = () => {
    return useQuery({
        queryKey: ['get-trustpilot'],
        queryFn: async () => {
            const path = ORCH_PATHS.TRUSTPILOT;
            try {
                const response = await orchestration.get(path);
                return trustpilotValidator.parse(response.data);
            } catch (cause) {
                throw createError(cause, { path });
            }
        },
    });
};
