import { ReactNode, createContext, useContext, useState } from 'react';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';

import { BRANDS, THEME_UTILITIES, brandProviderFactory, useBrand } from '@cof/plastic-components';

import allThemes from '../themes';

const { useColor } = THEME_UTILITIES;

/**
 * Brands that should use their own colour palettes (rather than the default colour palette).
 */
export const BRAND_COLOUR_PALETTE_ALLOWLIST = [BRANDS.CAPITAL_ONE, BRANDS.POST_OFFICE, BRANDS.ASOS, BRANDS.OCEAN];

/**
 * Brands that should display their own logos (rather than the default logo).
 */
export const BRAND_LOGO_ALLOWLIST = [
    BRANDS.CAPITAL_ONE,
    BRANDS.POST_OFFICE,
    BRANDS.VERY,
    BRANDS.LITTLEWOODS,
    BRANDS.ASOS,
    BRANDS.OCEAN,
];

/**
 * Maps the Web Servicing brand string (from orch) to the equivalent brand string
 */
export const BRAND_MAP_WS_TO_PLASTIC: Record<string, string> = {
    capitalone: BRANDS.CAPITAL_ONE,
    postoffice: BRANDS.POST_OFFICE,
    littlewoods: BRANDS.LITTLEWOODS,
    luma: BRANDS.LUMA,
    oceanfinance: BRANDS.OCEAN,
    thinkmoney: BRANDS.THINK_MONEY,
    very: BRANDS.VERY,
    asos: BRANDS.ASOS,
};

/**
 * Maps the Web Servicing brand string (from orch) to the equivalent brand string
 */
export const BRAND_MAP_PLASTIC_TO_WS: Record<string, string> = {
    [BRANDS.CAPITAL_ONE]: 'capitalone',
    [BRANDS.POST_OFFICE]: 'postoffice',
    [BRANDS.LITTLEWOODS]: 'littlewoods',
    [BRANDS.LUMA]: 'luma',
    [BRANDS.OCEAN]: 'oceanfinance',
    [BRANDS.THINK_MONEY]: 'thinkmoney',
    [BRANDS.VERY]: 'very',
    [BRANDS.ASOS]: 'asos',
};

/**
 * Strategy for loading the initial brand before we make any calls to /ataglance.
 * We check for the current brand in this order:
 * 1. localStorage 'brand' key value (/ataglance API call will set this value)
 * 2. "brand" URL search query param
 * 3. "channel" URL search query param (convert old servicing brand name to plastic one)
 * 4. return BRANDS.CAPITAL_ONE if no others have been found
 */
export const getInitialBrand = () => {
    const searchQuery = new URLSearchParams(window.location.search);

    const brandFromLocalStorage = localStorage.getItem('brand') || '';
    if (Object.values(BRAND_MAP_WS_TO_PLASTIC).includes(brandFromLocalStorage)) {
        return brandFromLocalStorage;
    }

    const brandFromLocation = searchQuery.get('brand') || '';
    if (Object.values(BRAND_MAP_WS_TO_PLASTIC).includes(brandFromLocation)) {
        return brandFromLocation;
    }

    const channelFromLocation = searchQuery.get('channel') || '';
    if (Object.keys(BRAND_MAP_WS_TO_PLASTIC).includes(channelFromLocation)) {
        return BRAND_MAP_WS_TO_PLASTIC[channelFromLocation];
    }

    return BRANDS.CAPITAL_ONE;
};

export const BrandStateContext = createContext<[string, React.Dispatch<React.SetStateAction<string>> | (() => void)]>([
    BRANDS.CAPITAL_ONE,
    () => {},
]);

export const useGetCurrentBrand = () => {
    return useContext(BrandStateContext)[0];
};

/**
 * App-specific brand provider - this allows us to update the current brand on the fly and provide the brand context to
 * the standard BrandProvider from plastic.
 * @param children
 */
export const UpdateBrandProvider = ({ children }: { children: ReactNode }) => {
    const brandState = useState(getInitialBrand);
    return <BrandStateContext.Provider value={brandState} children={children} />;
};

export const BrandProvider = brandProviderFactory({
    // @ts-ignore: bug in plastic which means this is always expected to be query string function
    brandProviderStrategy: useGetCurrentBrand,
    brandAllowList: BRAND_COLOUR_PALETTE_ALLOWLIST,
    defaultBrand: BRANDS.CAPITAL_ONE,
});

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const brand = useBrand();
    const { default: brandTheme } = allThemes[brand];
    return <BaseThemeProvider theme={brandTheme}>{children}</BaseThemeProvider>;
};

/**
 * In cases where partners have the exact same colourName, this allows you to pass in
 * brand.colorName as a prop and have that resolve to the appropriate colour.
 * E.g. brand.grey50 will be postOffice.grey50 for postOffice, and ocean.grey50 for ocean,
 * but for other brands which have no grey50, it will be global.grey50
 *
 * This hook is intended as a last resort. Alternatives to consider first are:
 *
 * 1. Using styled-components and adding a new key to the theme/componentColors file.
 * 2. Using the Plastic useColor function
 *
 * If (1) would require you to change a component to a styled component just because of one prop,
 * and (2) would require significant code duplication, then use of this hook might be justified
 *
 * The behaviour of this hook is deliberately only implemented for the colour grey50.
 * which was formerly SHADE_2 prior to plastic upgrade. No other colours should require
 * this as a workaround.
 *
 */
export const useBrandGrey = (props) => {
    const brandGrey = useColor({
        fallback: 'global.grey50',
        ocean: 'ocean.grey50',
        postOffice: 'postOffice.grey50',
    });

    const modifiedProps = { ...props };

    for (const prop in modifiedProps) {
        if ('brand.grey50' === modifiedProps[prop]) {
            modifiedProps[prop] = brandGrey;
        }
    }

    return { ...props, ...modifiedProps };
};

export const useBrandErrorColor = () => {
    return useColor({
        fallback: 'capitalOne.red550',
        ocean: 'ocean.red550',
        postOffice: 'postOffice.red550',
        ASOS: 'ASOS.red550',
        thinkMoney: 'thinkMoney.red550',
        very: 'very.red550',
        luma: 'luma.red550',
    });
};

/**
 * Returns a brandMap that is more lenient with property accessors.
 * Allows you to access a property of a brandMap, without worrying about casing, spacing etc.
 * For example obj.postOffice, obj.post_office, obj.POST_OFFICE etc. will all access the post office theme (if there is one).
 */
export const convertToLenientBrandMap = function (brandMap: { [key: string]: unknown }) {
    return new Proxy(brandMap, {
        get(target, name: string) {
            for (const prop of Object.keys(target)) {
                if (typeof prop !== 'string') {
                    continue;
                }
                const stringProp = prop as string;
                if (stringProp.toLowerCase().replace(/(-|_| )/g, '') === name.toLowerCase().replace(/(-|_| )/g, '')) {
                    return target[prop];
                }
            }
        },
    });
};
