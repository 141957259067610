import isPropValid from '@emotion/is-prop-valid';
import { Suspense, lazy, useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import {
    ApplicationLayout,
    BrandedFooter,
    CapitalOneLogoPrimaryLight,
    Header,
    THEME_CONSTANTS,
    useBrand,
} from '@cof/plastic-components';

import DelayedSpinner from './atoms/DelayedSpinner/DelayedSpinner';
import { DeepLinking } from './molecules/DeepLinking';
import { BrandedHeader } from './molecules/Header/Header';
import AtAGlance from './pages/AtAGlance/AtAGlance';
import ErrorBoundary from './pages/ErrorPages/ErrorBoundary';
import { ErrorPageHeader } from './pages/ErrorPages/ErrorPageTemplate';
import AccessRevoked from './pages/ErrorPages/pages/AccessRevoked';
import AuthError from './pages/ErrorPages/pages/AuthError';
import GenericError from './pages/ErrorPages/pages/GenericError';
import PaymentError from './pages/ErrorPages/pages/PaymentError';
import AuthenticatedLayout from './pages/Layouts/AuthenticatedLayout';
import { PATHS } from './utils/constants';

const BalanceTransfers = lazy(() => import('./pages/BalanceTransfer'));
const Clip = lazy(() => import('./pages/Clip'));
const ContactDetails = lazy(() => import('./pages/ContactDetails/ContactDetails'));
const CurrencyConverter = lazy(() => import('./pages/CurrencyConverter/CurrencyConverter'));
const HelpAndFAQS = lazy(() => import('./pages/Help'));
const Logout = lazy(() => import('./pages/ErrorPages/pages/Logout'));
const MakeAComplaint = lazy(() => import('./pages/Complaint'));
const ManageDD = lazy(() => import('./pages/ManageDD'));
const MyCard = lazy(() => import('./pages/MyCard/MyCard'));
const Payments = lazy(() => import('./pages/Payments/Payments'));
const MobilePayByBankResult = lazy(() => import('./pages/MobilePayByBankResult'));
const Permissions = lazy(() => import('./pages/Permissions/Permissions'));
const Preferences = lazy(() => import('./pages/Preferences/Preferences'));
const SelectPayment = lazy(() => import('./pages/Payments/SelectPayment'));
const Statements = lazy(() => import('./pages/Statements/Statements'));
const Timeout = lazy(() => import('./pages/ErrorPages/pages/Timeout'));

const { SCREEN_WIDTHS } = THEME_CONSTANTS;

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: unknown) {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
}

const DarkHeaderWithLogo = () => (
    <Header bg="capitalOne.blue750" maxWidth={SCREEN_WIDTHS.TEN_COLUMN}>
        <CapitalOneLogoPrimaryLight />
    </Header>
);

/*
The session storage payment is automatically cleared at the point of log in.
This is to protect users sharing a public computer
*/
sessionStorage.removeItem('previousPayment');

const App = () => {
    const brand = useBrand();
    useEffect(() => {
        // get app version from "version" meta tag in head and send it and brand to new relic
        if (window.newrelic && window.newrelic.setApplicationVersion) {
            const appVersion = document.head.querySelector('meta[name="version"]')?.getAttribute('content');
            window.newrelic.setApplicationVersion(appVersion ?? 'unknown');
            window.newrelic.setCustomAttribute('brand', brand);
        }
    }, [brand]);

    return (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <Routes>
                <Route element={<AuthenticatedLayout />} errorElement={<ErrorBoundary isAuthenticated />}>
                    <Route path={PATHS.AT_A_GLANCE} element={<AtAGlance />} />
                    <Route path={PATHS.BALANCE_TRANSFER} element={<BalanceTransfers />} />
                    <Route path={PATHS.MANAGE_DD} element={<ManageDD />} />
                    <Route path={PATHS.STATEMENTS} element={<Statements />} />
                    <Route path={PATHS.PAYMENTS} element={<Payments />} />
                    <Route path={`${PATHS.MAKE_PAYMENT}/*`} element={<SelectPayment />} />
                    <Route path={PATHS.MY_CARD} element={<MyCard />} />
                    <Route path={`${PATHS.CLIP}/*`} element={<Clip />} />
                    <Route path={`${PATHS.HELP}/*`} element={<HelpAndFAQS />} />
                    <Route path={`${PATHS.COMPLAINT}/*`} element={<MakeAComplaint />} />
                    <Route path={PATHS.PERMISSIONS} element={<Permissions />} />
                    <Route path={PATHS.PREFERENCES} element={<Preferences />} />
                    <Route path={PATHS.CONTACT_DETAILS} element={<ContactDetails />} />
                    <Route path={PATHS.MAKE_PAYMENT_ERROR} element={<PaymentError />} />
                    <Route path={PATHS.AUTHENTICATED_ERROR} element={<GenericError isAuthenticated />} />
                    <Route path={'*'} element={<GenericError isAuthenticated />} />
                </Route>
                <Route
                    path={PATHS.DEEP_LINKING}
                    element={<DeepLinking />}
                    errorElement={<ErrorBoundary isAuthenticated={false} />}
                />
                <Route
                    element={
                        <ApplicationLayout
                            Header={() => <BrandedHeader maxWidth={SCREEN_WIDTHS.TEN_COLUMN} bordered={false} />}
                            Footer={() => <BrandedFooter maxWidth={SCREEN_WIDTHS.TEN_COLUMN_NO_PADDING} />}
                        >
                            <Outlet />
                        </ApplicationLayout>
                    }
                    errorElement={<ErrorBoundary isAuthenticated={false} />}
                >
                    <Route path={PATHS.CURRENCY_CONVERTER} element={<CurrencyConverter />} />
                </Route>
                <Route
                    element={
                        <ApplicationLayout Header={DarkHeaderWithLogo} Footer={() => <></>}>
                            <Outlet />
                        </ApplicationLayout>
                    }
                    errorElement={<ErrorBoundary isAuthenticated={false} />}
                >
                    <Route path={`${PATHS.MOBILE_PAY_BY_BANK_RESULT}/*`} element={<MobilePayByBankResult />} />
                </Route>
                {/* Error pages */}
                <Route
                    element={
                        <ApplicationLayout
                            Header={ErrorPageHeader}
                            Footer={() => <BrandedFooter maxWidth={SCREEN_WIDTHS.TEN_COLUMN_NO_PADDING} />}
                        >
                            <Suspense fallback={<DelayedSpinner />}>
                                <Outlet />
                            </Suspense>
                        </ApplicationLayout>
                    }
                    errorElement={<ErrorBoundary isAuthenticated={false} />}
                >
                    <Route path={PATHS.LOGOUT} element={<Logout />} />
                    <Route path={PATHS.TIMEOUT} element={<Timeout />} />
                    <Route path={PATHS.AUTH_ERROR} element={<AuthError />} />
                    <Route path={PATHS.ACCESS_REVOKED} element={<AccessRevoked />} />
                    <Route path={PATHS.UNAUTHENTICATED_ERROR} element={<GenericError isAuthenticated={false} />} />
                </Route>
            </Routes>
        </StyleSheetManager>
    );
};

export default App;
