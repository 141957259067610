import { styled } from 'styled-components';

import { Box, Button, Flex, NewTabIcon, Paragraph } from '@cof/plastic-components';

import { useTrustpilot } from '../../data/trustpilot';
import { FEATURE_FLAGS, useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useGetCurrentBrand } from '../../utils/brand';
import TrustpilotLogo from './Trustpilot_Logo.svg';

const BannerWrapper = styled(Box)`
    ${({ theme }) => `
        border-top: 2px solid ${theme.colors.global.grey50};
        border-bottom: 2px solid ${theme.colors.global.grey50};
        margin: ${theme.space.lg} 0;
        padding: ${theme.space.md} 0;
    `}
`;

const addNewRelicPageAction = (actionName: string) => {
    console.log(`run event ${actionName}`);
    window.newrelic?.addPageAction(actionName);
    return true;
};

const TrustpilotContent = () => {
    const { data: trustpilot } = useTrustpilot();
    if (trustpilot?.trustpilotEligible) {
        addNewRelicPageAction('Trustpilot Banner Shown');
    }
    return trustpilot?.trustpilotEligible ? (
        <BannerWrapper data-qa-id="trustpilot-banner" position={'relative'}>
            <Flex alignItems={['flex-start', null, 'center']} flexDirection={['column', null, 'row']}>
                <Box width={[1, null, '60%']} mb={['xs', null]}>
                    <img src={TrustpilotLogo} alt="Trustpilot logo" />
                    <Paragraph pt="sm">
                        We want to hear about your experience with us so far. Just head to Trustpilot to tell us how
                        we&apos;re doing. It only takes a minute.{' '}
                    </Paragraph>
                </Box>
                <Flex justifyContent="flex-end" width={[1, null, '40%']}>
                    <Button
                        as="a"
                        href={trustpilot.trustpilotUrl}
                        variant="secondary"
                        data-qa-id="trustpilot-button"
                        onClick={() => addNewRelicPageAction('Trustpilot Banner Clicked')}
                    >
                        <Flex justifyContent="center" alignContent="center">
                            Leave a review
                            <NewTabIcon ml="xs" />
                        </Flex>
                    </Button>
                </Flex>
            </Flex>
        </BannerWrapper>
    ) : (
        <></>
    );
};

const TrustpilotBanner = () => {
    const brand = useGetCurrentBrand(); // need to use this hook as we need the actual brand rather than after filtering through allowlist for the color palette
    const trustPilotFeatureFlag = useFeatureFlag(FEATURE_FLAGS.TRUST_PILOT_BANNER);
    return brand === 'capitalOne' && trustPilotFeatureFlag ? <TrustpilotContent /> : <></>;
};

export default TrustpilotBanner;
